import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
//import { toast } from "react-toastify";
import { useTaxiActions } from "../store/useActions";
import AddressMenuElement from "./AddressMenuElement";
//import {useUISelector} from "../store/useSelectors";

const RedPoint = <div></div>;
const GreyPoint = <div className="grey-color"></div>;
const Slicer = (
  <div className="menu__slicer-wrap">
    <div className="menu__slicer"></div>
  </div>
);

const AddressMenu = (props) => {
  const [results, setResults] = useState([]);
  const [activeField, setActiveField] = useState("start");
  const { setStartAddress, setStartLocation, setEndAddress, setEndLocation } =
    useTaxiActions();


  const { startAddress, endAddress } = useSelector((state) => state.taxi);


  const onClickResult = (result) => {
    if (activeField === "start") {
      setStartAddress(result.set_name);
      setStartLocation({
        latitude: parseFloat(result.lat),
        longitude: parseFloat(result.lon),
      });
    } else {
      setEndAddress(result.set_name);
      setEndLocation({
        latitude: parseFloat(result.lat),
        longitude: parseFloat(result.lon),
      });
    }
  };

  return (
    <>
      <AddressMenuElement
        key="1"
        point={RedPoint}
        text={startAddress || ""}
        isInput={props.isInput}
        isStart={true}
        setResults={setResults}
        setActiveField={setActiveField}
        isEntranceRequired={props.isEntranceRequired}
      />

      {Slicer}

      <AddressMenuElement
        key="2"
        point={GreyPoint}
        text={endAddress || ""}
        style={{ paddingBottom: "12px" }}
        isInput={props.isInput}
        setResults={setResults}
        setActiveField={setActiveField}
      />
      <div
          className="address-results"
      >
        {results.map((result, idx) => {
            console.log(result)
          return (
            <Fragment key={idx}>
              <div
                className="address-result"
                onClick={() => onClickResult(result)}
              >
                  {Array.isArray(result.display_name) && result.display_name['city'] && result.display_name['address'] ? (
                      <>
                          <div
                              className="address-result-city"
                          >
                              {result.display_name['city']}
                          </div>
                          <div
                              className="address-result-address"
                          >
                              {result.display_name['address']}
                          </div>
                      </>
                      ) : (
                          typeof result.display_name === 'string' ?
                              result.display_name :
                              result.set_name
                  )}

              </div>
              {Slicer}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default AddressMenu;
